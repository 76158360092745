import { LockClosedIcon } from '@heroicons/react/24/outline'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useState } from 'react'
import { useField } from 'remix-validated-form'
import FormInputWrapper, { BaseInputClasses, FormInputWrapperProps } from './FormInputWrapper'

export default function FormPassword({
  name,
  label,
  placeholder,
  cols,
  className,
  ...props
}: FormInputWrapperProps<HTMLInputElement>) {
  const [visibility, setVisibility] = useState(false)
  const { error, getInputProps } = useField(name)

  const handleClick = () => {
    setVisibility((current) => !current)
  }

  const getInputType = () => (visibility ? `text` : `password`)

  const inputClasses = classNames(
    className,
    BaseInputClasses,
    `read-only:cursor-not-allowed read-only:border-gray-200 read-only:bg-gray-50 read-only:text-gray-500`,
  )

  return (
    <>
      <FormInputWrapper {...{ name, label, error, cols }}>
        <div className="relative">
          <div className="absolute top-3 left-2 h-full w-6 text-body-sm leading-5">
            <LockClosedIcon />
          </div>
          <input
            {...getInputProps({ ...props, id: name, type: getInputType(), placeholder })}
            className={classNames(inputClasses, `pl-[40px]`)}
          />
          <div className="absolute top-0 right-0 flex h-full w-8 pr-3 text-body-sm leading-5" onClick={handleClick}>
            {!visibility && <EyeIcon className="cursor-pointer" />}
            {visibility && <EyeSlashIcon className="cursor-pointer" />}
          </div>
        </div>
      </FormInputWrapper>
    </>
  )
}
